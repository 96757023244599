import { FC } from 'react';
import Header from './Header/Header';
import './PageLayout.scss';

export interface PageLayoutProps {
  Content: FC<any>;
}

export const PageLayout: FC<PageLayoutProps> = ({ Content }) => {
  return (
    <div className="page-layout">
      <Header />
      <Content />
    </div>
  );
};
