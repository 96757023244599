import classnames from 'classnames';
import { useAtom } from 'jotai';
import { Avatar, Button } from '@mui/material';
import { Trans } from 'react-i18next';

import { useMapDragState } from 'packages/frontend/src/store/MapDragState';
import { mapModeAtom } from 'packages/frontend/src/store';
import { normalizeIcon } from 'packages/frontend/src/common/icon';
import { usePersonUpdate } from 'packages/frontend/src/hooks/persons';
import { getFreePosition } from './utils';

import css from './UnplacedPerson.module.scss';

const classes = classnames.bind(css);

interface Props {
  person: Person;
}

export const UnplacedPerson = ({ person }: Props) => {
  const [mapDragState] = useMapDragState();
  const [, setMapMode] = useAtom(mapModeAtom);

  const { mutate } = usePersonUpdate();

  const icon = normalizeIcon(person);
  const { name } = person;

  const placePerson = (person: Person) => {
    const payload = getFreePosition(mapDragState);

    mutate({
      personId: person.id,
      payload,
    });

    setMapMode(null);
  };

  return (
    <div className={classes(css.item)}>
      <Avatar alt={name} src={icon} />
      <div className={classes(css.name)}>{name}</div>
      <Button
        className={classes(css.placeButton)}
        onClick={() => placePerson(person)}
      >
        <Trans>unplaced.place</Trans>
      </Button>
    </div>
  );
};
