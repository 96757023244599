import { Box } from '@mui/material';
import classNames from 'classnames';
import { useAtom } from 'jotai';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  useGesture,
  Handler as GestureHandler,
  WebKitGestureEvent,
} from '@use-gesture/react';

import { usePersonsByOffice } from '../../hooks/persons';
import { usePlacesByOffice } from '../../hooks/places';
import { IDragItem } from '../../store/MapDragState';
import {
  mapModeAtom,
  selectedPersonAtom,
  selectedPlaceAtom,
} from '../../store';
import { useOffices } from '../../store/office';
import { useUser } from '../../store/user';
import { OfficeMapInner } from './OfficeMapInner';

import css from './styles.module.scss';

const MIN_SCALE = 0.1;
const MAX_SCALE = 1;

const classes = classNames.bind(css);

// TODO: Cannot scroll with mobile device when page is not zoomed in (Safari 16)

/***
 * Implements a 'viewport' with a <DraggableMap /> inside it.
 */
export const OfficeMap = () => {
  const ref = useRef<HTMLDivElement>(null);

  const [zoomFactor, setZoomFactor] = useState(0.1);

  const { selectedOffice } = useOffices();
  const [selectedPerson] = useAtom(selectedPersonAtom);
  const [selectedPlace] = useAtom(selectedPlaceAtom);
  const [mapMode] = useAtom(mapModeAtom);
  const user = useUser();

  const { data: persons } = usePersonsByOffice({ office: selectedOffice });
  const { data: places } = usePlacesByOffice(selectedOffice);

  const [mapDragState, setMapDragState] = useState<
    Omit<IDragItem, 'id' | 'type'>
  >({
    top: 0,
    left: 0,
  });

  const handleZoomMovement = useCallback(
    (prevZoom: number, newZoom: number, evt?: WheelEvent) => {
      const containerRect = ref.current?.getBoundingClientRect();

      if (!containerRect || prevZoom === newZoom) {
        return;
      }

      const { x: containerX, y: containerY } = containerRect;

      // Zoom into middle of container if event is not passed
      const { clientX, clientY } = evt
        ? evt
        : {
            clientX: containerX + containerRect.width / 2,
            clientY: containerY + containerRect.height / 2,
          };

      const zoomDeltaFactor = (newZoom - prevZoom) / prevZoom;

      setMapDragState((prevDragState) => {
        const mapCenterX = containerX + prevDragState.left;
        const mapCenterY = containerY + prevDragState.top;

        const zoomOffsetX = clientX - mapCenterX;
        const zoomOffsetY = clientY - mapCenterY;

        const moveX = -zoomOffsetX * zoomDeltaFactor;
        const moveY = -zoomOffsetY * zoomDeltaFactor;

        return {
          top: prevDragState.top + moveY,
          left: prevDragState.left + moveX,
        };
      });
    },
    [setMapDragState, ref.current],
  );

  const handleOnWheel: GestureHandler<'wheel', WheelEvent> = useCallback(
    ({ active, event, delta: [, delta] }) => {
      if (active) {
        setZoomFactor((current) => {
          const scale = Math.min(
            Math.max(current + (delta * -1) / 1500, MIN_SCALE),
            MAX_SCALE,
          );

          handleZoomMovement(current, scale, event);

          return scale;
        });
      }
    },
    [setZoomFactor, handleZoomMovement],
  );

  const handleOnPinch: GestureHandler<
    'pinch',
    WheelEvent | PointerEvent | TouchEvent | WebKitGestureEvent
  > = useCallback(
    ({ active, movement: [movement], direction: [direction] }) => {
      // movement value seems to be ~2x bigger when zooming
      const diff = (direction === 1 ? movement / 2 : movement * -1) / 50;

      if (active) {
        setZoomFactor((current) => {
          const scale = Math.min(
            Math.max(current + diff, MIN_SCALE),
            MAX_SCALE,
          );

          // Don't pass event as clientX and clientY varies between fingers -> map teleports on zoom
          handleZoomMovement(current, scale);

          return scale;
        });
      }
    },
    [setZoomFactor, handleZoomMovement],
  );

  // TODO: Limit dragging too far off
  const moveMapOnDrag: GestureHandler<'drag'> = useCallback(
    ({ delta: [dx, dy], touches }) => {
      if (touches > 1) {
        return;
      }

      setMapDragState((state) => ({
        top: state.top + dy,
        left: state.left + dx,
      }));
    },
    [setMapDragState],
  );

  const bind = useGesture(
    {
      onWheel: handleOnWheel,
      onPinch: handleOnPinch,
      onDrag: moveMapOnDrag,
    },
    {
      eventOptions: { passive: false },
    },
  );

  useEffect(() => {
    if (ref.current && selectedOffice) {
      const { height, width } = ref.current.getBoundingClientRect();
      const { mapHeight, mapWidth } = selectedOffice;

      // Initialize map drag state
      setMapDragState({
        top: height / 2,
        left: width / 2,
      });

      // Initialize zoom factor (fit map to container if possible)
      const scale = Math.min((height - 8) / mapHeight, (width - 8) / mapWidth);

      setZoomFactor(Math.min(Math.max(scale, MIN_SCALE), MAX_SCALE));
    }
  }, [ref.current, selectedOffice]);

  useEffect(() => {
    const rect = ref.current?.getBoundingClientRect();
    if (!rect || (!selectedPerson && !selectedPlace)) return;

    const person = persons?.find((a) => a.id === selectedPerson?.id);
    const place = places?.find((a) => a.id === selectedPlace?.id);

    let selectedX = 0;
    let selectedY = 0;

    if (person && person.positionX && person.positionY) {
      selectedX = person.positionX;
      selectedY = person.positionY;
    }

    if (place && place.positionX && place.positionY) {
      selectedX = place.positionX;
      selectedY = place.positionY;
    }

    setZoomFactor(1);
    setMapDragState({
      top: selectedOffice!.mapHeight / 2 - selectedX + rect.height / 2,
      left: selectedOffice!.mapWidth / 2 - selectedY + rect.width / 2,
    });

    // focus on person or place on selection
    setTimeout(() => {
      const elemId = person
        ? `office-map-person-${selectedPerson?.id}`
        : `office-map-place-${selectedPlace?.id}`;

      document.getElementById(elemId)?.focus();
    }, 150);
  }, [selectedPerson, selectedPlace]);

  // Pan and zoom to the user, if on the map. Skip if an admin.
  useEffect(() => {
    if (typeof selectedOffice === 'undefined' || mapMode === null) {
      return;
    }

    const rect = ref.current?.getBoundingClientRect();
    if (typeof rect === 'undefined') {
      return;
    }

    const person = persons?.find(({ id }) => id === user?.id);
    if (typeof person === 'undefined') {
      return;
    }

    const { positionX, positionY } = person;
    if (!positionX || !positionY) {
      return;
    }

    setZoomFactor(1);

    const { mapWidth, mapHeight } = selectedOffice;
    const { width, height } = rect;

    setMapDragState({
      top: mapHeight / 2 - positionX + height / 2,
      left: mapWidth / 2 - positionY + width / 2,
    });
  }, [mapMode]);

  return (
    <Box ref={ref} className={classes(css.container)} {...bind()}>
      <Box
        component="div"
        sx={{
          position: 'absolute',
          transform: `translate(${mapDragState.left}px, ${
            mapDragState.top
          }px) translate(-50%, -50%) scale(${zoomFactor ?? 1})`,
          transitionProperty: 'transform',
          touchAction: 'none',
          userSelect: 'none',
          width: selectedOffice?.mapWidth,
          height: selectedOffice?.mapHeight,
        }}
      >
        <OfficeMapInner zoomFactor={zoomFactor} />
      </Box>
    </Box>
  );
};
