import { Button, Container, Grid, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import { FallbackProps } from 'react-error-boundary';
import { Trans } from 'react-i18next';
import LoadingBackdrop from '../Components/LoadingBackdrop';

const ErrorBoundaryFallback = ({
  resetErrorBoundary,
  error,
}: FallbackProps) => {
  let method: string | undefined;
  let url: string | undefined;
  let message = error.message;

  if (error instanceof AxiosError) {
    const { response, config, message: msg } = error as AxiosError;

    if (response?.status === 401) {
      return <LoadingBackdrop open bgColor="#d75539" />;
    }

    method = config?.method?.toLocaleUpperCase();
    url = config?.url;
    message = msg;
  }

  return (
    <Container
      sx={{ my: 2, display: 'flex', flexDirection: 'column', flexGrow: 1 }}
      maxWidth="md"
    >
      <Typography variant="h3">
        <Trans>fallback.title</Trans>
      </Typography>
      {typeof error !== 'undefined' && (
        <div>
          <Typography variant="h5">
            <p>
              <i>
                {method} {url}
              </i>
            </p>
            {typeof message !== 'undefined' && <p>{message}</p>}
          </Typography>
          {typeof resetErrorBoundary !== 'undefined' && (
            <Grid container gap={1} direction="row">
              <Button onClick={() => resetErrorBoundary()} variant="contained">
                <Trans>fallback.btn.retry</Trans>
              </Button>
              <Button
                onClick={() => window.location.reload()}
                variant="contained"
              >
                <Trans>fallback.btn.reload</Trans>
              </Button>
            </Grid>
          )}
        </div>
      )}
    </Container>
  );
};

export default ErrorBoundaryFallback;
