import classNames from 'classnames';
import vincitLogo from '../../assets/images/Vincitoylogo.svg';
import css from './Header.module.scss';
import MainMenu from './MainMenu';
import OfficeSelector from './OfficeSelector';
import PersonSelector from './PersonSelector';
import PlaceSelector from './PlaceSelector';
import ToggleGroup from './ToggleGroup';

const classes = classNames.bind(css);

const Header = () => (
  <div className={classes(css.header)}>
    <div className={classes(css.logoContainer)}>
      <img className={classes(css.logo)} src={vincitLogo} alt="Vincit Logo" />
    </div>
    <div className={classes(css.selector)}>
      <OfficeSelector />
    </div>
    <div className={classes(css.selector)}>
      <PersonSelector />
    </div>
    <div className={classes(css.selector)}>
      <PlaceSelector />
    </div>
    <div className={classes(css.editButtons)}>
      <ToggleGroup />
    </div>
    <div className={classes(css.mainMenu)}>
      <MainMenu />
    </div>
  </div>
);

export default Header;
