import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { createContext, FC, PropsWithChildren, useContext } from 'react';

import { usePerson } from '../hooks/persons';
import { AUTH_PATH, LOGIN_PATH } from 'packages/common/src/constants';
import { useEffectOnce } from 'usehooks-ts';

const COOKIE_KEY = '__user';

const UserContext = createContext<Person | undefined | null>(null);

export const UserContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();

  const userFromCookie = JSON.parse(Cookies.get(COOKIE_KEY) ?? 'null');
  const { data: user } = usePerson(userFromCookie?.id);

  useEffectOnce(() => {
    if (!userFromCookie) {
      window.location.replace(
        `${AUTH_PATH}${LOGIN_PATH}?redirect_url=${window.location.href}`,
      );
    } else if (userFromCookie.firstLogin) {
      Cookies.set(
        COOKIE_KEY,
        JSON.stringify({ ...userFromCookie, firstLogin: undefined }),
      );
      navigate('/welcome');
    }
  });

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};

export const useUser = () => useContext(UserContext);
