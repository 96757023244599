import { FC, useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { usePersonUpdate } from 'packages/frontend/src/hooks/persons';
import CheckIconButton from '../../Icons/CheckIconButton';
import CloseIconButton from '../../Icons/CloseIconButton';
import EditIconButton from '../../Icons/EditIconButton';

import css from './styles.module.scss';
import colors from '../../../scss/colors.module.scss';

const classes = classnames.bind(css);

interface Props {
  user: Person;
}

export const EditUserName: FC<Props> = ({ user }) => {
  const { t } = useTranslation();
  const [edit, setEdit] = useState<boolean>(false);

  const { mutate } = usePersonUpdate();

  const FormSchema = z.object({
    name: z
      .string({
        required_error: t('settings.form.error.name-required') as string,
      })
      .min(3, { message: t('settings.form.error.name-short') as string }),
  });

  type FormSchemaType = z.infer<typeof FormSchema>;

  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    reset,
  } = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    mode: 'onChange',
    defaultValues: {
      name: user.name,
    },
  });

  const onSubmit: SubmitHandler<FormSchemaType> = ({ name }) => {
    mutate({ personId: user.id, payload: { name } });
    setEdit(false);
  };

  const onClose = () => {
    reset();
    setEdit(false);
  };

  const handleEscape = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      onClose();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleEscape);

    return () => document.removeEventListener('keydown', handleEscape);
  }, []);

  return edit ? (
    <form
      className={classes(css.container)}
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
      noValidate
    >
      <Controller
        name="name"
        control={control}
        render={({ field }) => (
          <div className={classes(css.inputContainer)}>
            <input className={classes(css.input)} {...field} />
            <div className={classes(css.error)}>{errors.name?.message}</div>
          </div>
        )}
      />
      <CheckIconButton
        color={colors.black}
        type="submit"
        disabled={!isValid || !isDirty}
      />
      <CloseIconButton color={colors.black} onClick={onClose} />
    </form>
  ) : (
    <div className={classes(css.container)}>
      <div className={classes(css.userName)}>{user.name}</div>
      <EditIconButton color={colors.black} onClick={() => setEdit(true)} />
    </div>
  );
};
