import classnames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'usehooks-ts';
import LanguageIcon from '@mui/icons-material/Language';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Menu, MenuItem } from '@mui/material';

import {
  STORAGE_LANGUAGE_PREFERENCE,
  STORAGE_DEFAULT_LANGUAGE,
} from '../../../i18n/config';

import css from './styles.module.scss';

const classes = classnames.bind(css);

const langOptions = ['en', 'fi'];

export const LanguageSelector = () => {
  const [ref, setRef] = useState<HTMLButtonElement | null>(null);

  const { t, i18n } = useTranslation();
  const [, setLang] = useLocalStorage(
    STORAGE_LANGUAGE_PREFERENCE,
    STORAGE_DEFAULT_LANGUAGE,
  );

  const handleChange = (key: string) => {
    i18n.changeLanguage(key);
    setLang(key);
    setRef(null);
  };

  return (
    <div className={classes(css.container)}>
      <button
        className={classes(css.button)}
        onClick={(e) => setRef(e.currentTarget)}
      >
        <LanguageIcon fontSize="small" sx={{ marginRight: '8px' }} />
        {t(`profile.language.${i18n.language}`)}
        {ref ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </button>
      <Menu anchorEl={ref} open={!!ref} onClose={() => setRef(null)}>
        {langOptions.map((option) => (
          <MenuItem key={option} onClick={() => handleChange(option)}>
            {t(`profile.language.${option}`)}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
