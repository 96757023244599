import { Button, Typography } from '@mui/material';
import classnames from 'classnames';
import {
  AUTH_PATH,
  LOGIN_PATH,
  LOGOUT_PATH,
} from 'packages/common/src/constants';
import { Trans } from 'react-i18next';
import { useEffectOnce } from 'usehooks-ts';
import axiosInstance from '../api/axios';

import css from './LogoutPage.module.scss';

const classes = classnames.bind(css);

const LogoutPage = () => {
  const logout = async () => {
    await axiosInstance.post(`${AUTH_PATH}${LOGOUT_PATH}`);
  };

  useEffectOnce(() => {
    logout();
  });

  return (
    <div className={classes(css.container)}>
      <div className={classes(css.card)}>
        <Typography variant="h4">
          <Trans>main.logged-out.title</Trans>
        </Typography>
        <Button
          variant="contained"
          sx={{ mt: 2 }}
          onClick={() => (window.location.href = `${AUTH_PATH}${LOGIN_PATH}`)}
        >
          <Trans>main.logged-out.relogin</Trans>
        </Button>
      </div>
    </div>
  );
};

export default LogoutPage;
