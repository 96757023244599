import { zodResolver } from '@hookform/resolvers/zod';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { Transition } from '../common/ConfirmDialog';

interface Props {
  open: boolean;
  onCancel: () => void;
  onConfirm: (name: string) => void;
}

export const AddPlaceDialog = ({ open, onCancel, onConfirm }: Props) => {
  const { t } = useTranslation();

  const FormSchema = z.object({
    name: z
      .string({
        required_error: t('dialog.places.error.name-required') as string,
      })
      .min(2, { message: t('dialog.places.error.name-short') as string }),
  });

  type FormSchemaType = z.infer<typeof FormSchema>;

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    mode: 'onChange',
    defaultValues: { name: '' },
  });

  const onSubmit: SubmitHandler<FormSchemaType> = ({ name }) => {
    onConfirm(name);
    reset();
  };

  const handleCancel = () => {
    onCancel();
    reset();
  };

  return (
    <Dialog
      TransitionComponent={Transition}
      onClose={handleCancel}
      open={open}
      keepMounted
      aria-describedby="add-place-dialog-description"
    >
      <DialogTitle>{t('dialog.places.title.add-new')}</DialogTitle>
      <DialogContent>
        <Box
          style={{ height: '50px' }}
          component="form"
          autoComplete="off"
          noValidate
        >
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                label={t('dialog.places.field.name')}
                variant="standard"
                color="secondary"
                type="text"
                error={typeof errors.name !== 'undefined'}
                helperText={errors.name?.message}
                onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
              />
            )}
            control={control}
            name="name"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleCancel}>
          {t('dialog.confirm.btn.cancel')}
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          disabled={!isValid}
        >
          {t('dialog.confirm.btn.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
