import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axiosInstance from '../api/axios';
import { API_PATH, PLACES_PATH } from '../../../common/src/constants';

const API_PLACES_PATH = `${API_PATH}${PLACES_PATH}`;

/* GET. */

const fetchPlaces = (): Promise<Place[]> => axiosInstance.get(API_PLACES_PATH);

export const usePlaces = () => useQuery(['places'], fetchPlaces);

const fetchPlacesByOffice = (office: Office | undefined): Promise<Place[]> => {
  return typeof office?.id !== 'undefined'
    ? axiosInstance.get(`${API_PLACES_PATH}/by-office/${office.id}`)
    : Promise.reject(new Error('invalid office id (undefined)'));
};

export const usePlacesByOffice = (office: Office | undefined) => {
  return useQuery(
    ['places', 'places-by-office', office?.id],
    () => fetchPlacesByOffice(office),
    { enabled: Boolean(typeof office !== 'undefined') },
  );
};

/* POST. */

interface PostOptions {
  name: string;
  positionX: number;
  positionY: number;
  office: number;
}

const postPlace = (payload: PostOptions): Promise<void> => {
  return axiosInstance.post(API_PLACES_PATH, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const usePlaceAdd = () => {
  const queryClient = useQueryClient();

  return useMutation((o: PostOptions) => postPlace(o), {
    onSuccess: () => queryClient.invalidateQueries(['places']),
  });
};

/* PATCH. */

interface UpdateOptions {
  payload: PlaceRequest;
  placeId: number;
}

const patchPlace = ({ placeId, payload }: UpdateOptions): Promise<void> => {
  return axiosInstance.patch(`${API_PLACES_PATH}/${placeId}/`, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const usePlaceUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation((o: UpdateOptions) => patchPlace(o), {
    onSuccess: () => queryClient.invalidateQueries(['places']),
  });
};

/* DELETE. */

interface DeleteOptions {
  placeId: number;
}

const deletePlace = ({ placeId }: DeleteOptions): Promise<void> => {
  return axiosInstance.delete(`${API_PLACES_PATH}/${placeId}/`);
};

export const usePlaceRemove = () => {
  const queryClient = useQueryClient();

  return useMutation((o: DeleteOptions) => deletePlace(o), {
    onSuccess: () => queryClient.invalidateQueries(['places']),
  });
};
