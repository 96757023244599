import { Button, Grid, Typography } from '@mui/material';
import classnames from 'classnames';
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Card } from '../../Components/common/Card';
import css from './styles.module.scss';

const classes = classnames.bind(css);

const WelcomePage = () => {
  const navigate = useNavigate();

  return (
    <div className={classes(css.container)}>
      <div className={classes(css.content)}>
        <Card>
          <Typography variant="h4">
            <Trans>welcome.title</Trans>
          </Typography>
          <Typography variant="body1" sx={{ mt: 4 }}>
            <Trans>welcome.message</Trans>
          </Typography>
          <Grid container gap={1} direction="row" sx={{ mt: 4 }}>
            <Button onClick={() => navigate('/')} variant="contained">
              <Trans>welcome.btn.later</Trans>
            </Button>
            <Button onClick={() => navigate('/profile')} variant="contained">
              <Trans>welcome.btn.profile</Trans>
            </Button>
          </Grid>
        </Card>
      </div>
    </div>
  );
};

export default WelcomePage;
