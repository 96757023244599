import i18n from 'i18next';
import { useEffectOnce, useLocalStorage } from 'usehooks-ts';
import {
  STORAGE_DEFAULT_LANGUAGE,
  STORAGE_LANGUAGE_PREFERENCE,
} from './i18n/config';
import Router from './router/Router';

const App = () => {
  const [language] = useLocalStorage(
    STORAGE_LANGUAGE_PREFERENCE,
    STORAGE_DEFAULT_LANGUAGE,
  );

  useEffectOnce(() => {
    i18n.changeLanguage(language);
  });

  return <Router />;
};

export default App;
