import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axiosInstance from '../api/axios';
import { API_PATH, OFFICES_PATH } from '../../../common/src/constants';

const API_OFFICES_PATH = `${API_PATH}${OFFICES_PATH}` as const;

/* GET. */

const fetchOffices = (): Promise<Office[]> =>
  axiosInstance.get(API_OFFICES_PATH);

export const useOfficeQuery = () => useQuery(['offices'], fetchOffices);

/* POST. */

interface PostOptions {
  name: string;
  image: File;
}

const postOffice = (payload: PostOptions): Promise<void> => {
  return axiosInstance.post(API_OFFICES_PATH, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const useOfficeAdd = () => {
  const queryClient = useQueryClient();

  return useMutation((o: PostOptions) => postOffice(o), {
    onSuccess: () => queryClient.invalidateQueries(['offices']),
  });
};

/* PATCH. */

interface UpdateOptions {
  payload: OfficeRequest;
  officeId: number;
}

const patchOffice = ({ officeId, payload }: UpdateOptions): Promise<void> => {
  return axiosInstance.patch(`${API_OFFICES_PATH}/${officeId}/`, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const useOfficeUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation((o: UpdateOptions) => patchOffice(o), {
    onSuccess: () => queryClient.invalidateQueries(['offices']),
  });
};

/* DELETE. */

interface DeleteOptions {
  officeId: number;
}

const deleteOffice = ({ officeId }: DeleteOptions): Promise<void> => {
  return axiosInstance.delete(`${API_OFFICES_PATH}/${officeId}/`);
};

export const useOfficeRemove = () => {
  const queryClient = useQueryClient();

  return useMutation((o: DeleteOptions) => deleteOffice(o), {
    onSuccess: () => queryClient.invalidateQueries(['offices']),
  });
};
