import english from './english.json';
import finnish from './finnish.json';

export const STORAGE_LANGUAGE_PREFERENCE = 'languagePreference';
export const STORAGE_DEFAULT_LANGUAGE = 'en';

export const i18nConfig = {
  resources: {
    en: { translation: english },
    fi: { translation: finnish },
  },
  // debug: true,
  // lng: 'en',
  fallbackLng: STORAGE_DEFAULT_LANGUAGE,
  interpolation: {
    escapeValue: false,
  },
};
