import { lazy, ReactElement, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import LoadingBackdrop from '../Components/LoadingBackdrop';
import { PageLayout } from '../Components/PageLayout';
import HomePage from '../pages/HomePage';
import LogoutPage from '../pages/LogoutPage';
import NotFoundPage from '../pages/NotFoundPage';
import { UserProfilePage } from '../pages/UserProfilePage';
import WelcomePage from '../pages/WelcomePage';
import { paths } from './paths';

interface RouteOption {
  path: string;
  element: ReactElement;
}

const SettingsPage = lazy(() => import('../pages/SettingsPage'));

const routes: RouteOption[] = [
  {
    path: paths.home,
    element: <PageLayout Content={HomePage} />,
  },
  {
    path: paths.settings,
    element: (
      <Suspense fallback={<LoadingBackdrop open bgColor="#d75539" />}>
        <SettingsPage />
      </Suspense>
    ),
  },
  {
    path: paths.userProfile,
    element: <UserProfilePage />,
  },
  {
    path: paths.welcome,
    element: <WelcomePage />,
  },

  {
    path: paths.logout,
    element: <LogoutPage />,
  },
  {
    path: paths.notFound,
    element: <NotFoundPage />,
  },
  {
    path: '*',
    element: <Navigate to={paths.notFound} replace />,
  },
];

export const Router = () => (
  <Routes>
    {routes.map((route) => (
      <Route key={route.path} path={route.path} element={route.element} />
    ))}
  </Routes>
);

export default Router;
