import { FC } from 'react';
import classNames from 'classnames';
import { useAtom } from 'jotai';
import { Tooltip, Typography } from '@mui/material';
import PlaceIcon from '@mui/icons-material/Place';

import { mapModeAtom } from '../../../store';
import { usePlaces } from '../../../hooks/places';

import css from './styles.module.scss';

const classes = classNames.bind(css);

export const OfficePlace: FC<{ stateId: string }> = ({ stateId }) => {
  const [mapMode] = useAtom(mapModeAtom);
  const places = usePlaces();

  const place = places.data?.find(({ id }) => id.toString() === stateId);

  return (
    <Tooltip
      title={<Typography color="inherit">{place?.name}</Typography>}
      placement="right"
      enterDelay={0}
      enterTouchDelay={0}
      leaveDelay={200}
      arrow
      PopperProps={{
        onPointerDown: (e) => {
          e.stopPropagation();
        },
      }}
    >
      {place?.icon ? (
        <img
          id={`office-map-place-${place?.id}`}
          src={`${process.env.REACT_APP_IMAGE_BUCKET}/${place?.icon}`}
          className={classes(css.place, {
            [css.moveEnabled]: mapMode === 'move',
          })}
          tabIndex={0}
          draggable={false}
        />
      ) : (
        <PlaceIcon
          id={`office-map-place-${place?.id}`}
          sx={{ width: 65, height: 65, color: 'blue' }}
          className={classes(css.place, {
            [css.moveEnabled]: mapMode === 'move',
          })}
          tabIndex={0}
        />
      )}
    </Tooltip>
  );
};
