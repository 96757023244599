import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import Select, { SingleValue } from 'react-select';
import {
  mapModeAtom,
  selectedPersonAtom,
  selectedPlaceAtom,
} from '../../store';
import { useOffices } from '../../store/office';
import { customStyles } from './SelectorStyles';

const OfficeSelector = () => {
  const { t } = useTranslation();

  const { offices, selectedOffice, setSelectedOffice } = useOffices();

  const [, setSelectedPerson] = useAtom(selectedPersonAtom);
  const [, setSelectedPlace] = useAtom(selectedPlaceAtom);
  const [, setMapMode] = useAtom(mapModeAtom);

  const handleChange = (option: SingleValue<Office> | null) => {
    if (option) {
      setSelectedOffice(option);
      setSelectedPerson(null);
      setSelectedPlace(null);
      setMapMode(null);
    }
  };

  if (!selectedOffice)
    return (
      <Select
        placeholder={t('main.selector.offices')}
        onChange={handleChange}
        options={offices}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id.toString()}
      />
    );

  return (
    <Select
      styles={customStyles}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          neutral50: '#443938', // Placeholder color
        },
      })}
      placeholder={selectedOffice.name}
      value={selectedOffice}
      options={offices}
      onChange={handleChange}
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id.toString()}
    />
  );
};

export default OfficeSelector;
