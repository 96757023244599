import { FC, PropsWithChildren, useCallback, useState } from 'react';
import { useGesture, Handler as GestureHandler } from '@use-gesture/react';
import { Box } from '@mui/system';

import { usePersonUpdate } from '../../hooks/persons';
import { usePlaceUpdate } from '../../hooks/places';
import { useOffices } from '../../store/office';
import { getClosestFreePosition } from './utils';
import {
  DragItemType,
  IDragItem,
  setItemDragState,
  useMapDragState,
} from '../../store/MapDragState';

interface Props {
  item: IDragItem;
  zoomFactor: number;
  dragDisabled?: boolean;
}

export const DragItem: FC<PropsWithChildren<Props>> = ({
  item,
  zoomFactor,
  dragDisabled,
  children,
}) => {
  const [dragging, setDragging] = useState<boolean>(false);

  const [mapDragState, dispatch] = useMapDragState();
  const { selectedOffice } = useOffices();

  const { mutate: updatePerson } = usePersonUpdate();
  const { mutate: updatePlace } = usePlaceUpdate();

  const onDrag: GestureHandler<'drag'> = useCallback(
    ({ event, offset: [x, y] }) => {
      event.stopPropagation();
      dispatch(
        setItemDragState({
          ...item,
          top: y,
          left: x,
        }),
      );
    },
    [],
  );

  const onDragEnd: GestureHandler<'drag'> = useCallback(
    ({ offset: [x, y] }) => {
      setDragging(false);

      let position = {
        positionX: Math.round(y),
        positionY: Math.round(x),
      };

      position = getClosestFreePosition({ mapDragState, item, ...position });

      dispatch(
        setItemDragState({
          ...item,
          top: position.positionX,
          left: position.positionY,
        }),
      );

      if (item.type === DragItemType.PERSON) {
        updatePerson({ personId: Number(item.id), payload: position });
      } else if (item.type === DragItemType.PLACE) {
        updatePlace({ placeId: Number(item.id), payload: position });
      }
    },
    [zoomFactor, mapDragState],
  );

  const bind = useGesture(
    {
      onDrag,
      onDragStart: () => setDragging(true),
      onDragEnd,
    },
    {
      drag: {
        enabled: !dragDisabled,
        triggerAllEvents: true,
        transform: ([x, y]) => [x / zoomFactor, y / zoomFactor],
        from: [item.left, item.top],
        bounds: {
          top: 1,
          left: 1,
          bottom: selectedOffice?.mapHeight ?? 2 - 1,
          right: selectedOffice?.mapWidth ?? 2 - 1,
        },
      },
    },
  );

  return (
    <Box
      {...bind()}
      component="div"
      sx={{
        position: 'absolute',
        touchAction: 'none',
        transform: `translate(${item.left}px, ${item.top}px) translate(-50%, -50%)`,
        transitionProperty: 'transform',
        transitionDuration: dragging ? 'unset' : '150ms',
        width: '65px',
        height: '65px',
      }}
    >
      {children}
    </Box>
  );
};
