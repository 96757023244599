import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useReducer,
} from 'react';

import { usePersonsByOffice } from '../../hooks/persons';
import { usePlacesByOffice } from '../../hooks/places';
import { useOffices } from '../office';
import {
  Action,
  reducer,
  setState,
  MapDragState,
  IDragItem,
  DragItemType,
} from './reducer';

const initialState: MapDragState = [];

const MapDragStateContext = createContext<[MapDragState, Dispatch<Action>]>([
  initialState,
  () => initialState,
]);

interface Props {
  children: ReactNode;
}

export const MapDragStateProvider: FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { selectedOffice } = useOffices();
  const { data: places } = usePlacesByOffice(selectedOffice);
  const { data: persons } = usePersonsByOffice({
    office: selectedOffice,
    type: 'seated',
  });

  useEffect(() => {
    if (!selectedOffice || !places || !persons) {
      return;
    }

    const personItems: IDragItem[] = persons.map(
      ({ id, positionX, positionY }) => ({
        id: id.toString(),
        type: DragItemType.PERSON,
        top: positionX!,
        left: positionY!,
      }),
    );

    const placeItems: IDragItem[] = places.map(
      ({ id, positionX, positionY }) => ({
        id: id.toString(),
        type: DragItemType.PLACE,
        top: positionX,
        left: positionY,
      }),
    );

    dispatch(setState([...personItems, ...placeItems]));
  }, [selectedOffice, places, persons]);

  return (
    <MapDragStateContext.Provider value={[state, dispatch]}>
      {children}
    </MapDragStateContext.Provider>
  );
};

export const useMapDragState = () => useContext(MapDragStateContext);
