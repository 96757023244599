import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import VincitLogo from '../../assets/images/Vincitoylogo.svg';
import { UserProfile } from '../../Components/UserProfile';
import { useUser } from '../../store/user';

import css from './styles.module.scss';

const classes = classnames.bind(css);

export const UserProfilePage = () => {
  const { t } = useTranslation();
  const user = useUser();

  return (
    <div className={classes(css.container)}>
      <div className={classes(css.navigation)}>
        <Link to={{ pathname: '/' }}>
          <img height={34} src={VincitLogo} />
        </Link>
        <Link className={classes(css.linkButton)} to={{ pathname: '/' }}>
          {t('profile.back')}
        </Link>
      </div>
      <div className={classes(css.content)}>
        {user?.id && <UserProfile userId={user.id} />}
      </div>
    </div>
  );
};
