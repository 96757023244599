import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axiosInstance from '../api/axios';
import { API_PATH, PERSONS_PATH } from '../../../common/src/constants';

const API_PERSONS_PATH = `${API_PATH}${PERSONS_PATH}` as const;

/* GET. */

const fetchPersons = (): Promise<Person[]> =>
  axiosInstance.get(API_PERSONS_PATH);

export const usePersons = () => useQuery(['persons'], fetchPersons);

const fetchPerson = (id: number): Promise<Person> =>
  axiosInstance.get(`${API_PERSONS_PATH}/${id}`);

export const usePerson = (id?: number) =>
  useQuery(['persons', id], () => (id ? fetchPerson(id) : null));

const fetchPersonsByOffice = ({
  office,
  type,
}: {
  office?: Office | null;
  type?: 'seated' | 'unseated';
}): Promise<Person[]> => {
  return office
    ? axiosInstance.get(
        `${API_PERSONS_PATH}/by-office/${office.id}?type=${type}`,
      )
    : Promise.reject(new Error('invalid office id (undefined)'));
};

export const usePersonsByOffice = ({
  office,
  type,
}: {
  office?: Office | null;
  type?: 'seated' | 'unseated';
}) => {
  return useQuery(
    ['persons', 'persons-by-office', String(office?.id), type],
    () => fetchPersonsByOffice({ office, type }),
    { enabled: !!office },
  );
};

const fetchUnseatedPersonsByOffice = (
  office: Office | undefined,
): Promise<Person[]> => {
  return typeof office?.id !== 'undefined'
    ? axiosInstance.get(
        `${API_PERSONS_PATH}/by-office/${office.id}?type=unseated`,
      )
    : Promise.reject(new Error('invalid office id (undefined)'));
};

export const usePersonsByOfficeAndUnseated = (office: Office | undefined) => {
  return useQuery(
    ['persons', 'persons-by-office-unseated', office?.id],
    () => fetchUnseatedPersonsByOffice(office),
    { enabled: Boolean(typeof office !== 'undefined') },
  );
};

const fetchRecentlyAddedPersons = (limit?: number): Promise<Person[]> =>
  axiosInstance.get(`${API_PERSONS_PATH}/recently-added?limit=${limit}`);

export const useRecentlyAddedPersons = (limit?: number) =>
  useQuery(['persons', 'recently-added', limit?.toString()], () =>
    fetchRecentlyAddedPersons(limit),
  );

/* POST. */

interface PostOptions {
  name: string;
  email?: string;
  phone?: string;
  mainOffice?: number;
  image?: File;
}

const postPerson = (payload: PostOptions): Promise<void> => {
  return axiosInstance.post(API_PERSONS_PATH, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const usePersonAdd = () => {
  const queryClient = useQueryClient();

  return useMutation((o: PostOptions) => postPerson(o), {
    onSuccess: () => queryClient.invalidateQueries(['persons']),
  });
};

/* PATCH. */

interface UpdateOptions {
  payload: PersonRequest;
  personId: number;
}

const patchPerson = ({ personId, payload }: UpdateOptions): Promise<void> => {
  return axiosInstance.patch(`${API_PERSONS_PATH}/${personId}/`, payload);
};

export const usePersonUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation((o: UpdateOptions) => patchPerson(o), {
    onSuccess: (/* data, variables */) => {
      queryClient.invalidateQueries(['persons']);
    },
  });
};

interface UpdateIconOptions {
  personId: number;
  image: File;
}

const patchPersonIcon = ({
  personId,
  image,
}: UpdateIconOptions): Promise<void> => {
  return axiosInstance.patch(
    `${API_PERSONS_PATH}/${personId}/`,
    { image } /* payload */,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
};

export const usePersonUpdateIcon = () => {
  const queryClient = useQueryClient();

  return useMutation((o: UpdateIconOptions) => patchPersonIcon(o), {
    onSuccess: () => queryClient.invalidateQueries(['persons']),
  });
};

/* DELETE. */

interface DeleteOptions {
  personId: number;
}

const deletePerson = ({ personId }: DeleteOptions): Promise<void> => {
  return axiosInstance.delete(`${API_PERSONS_PATH}/${personId}/`);
};

export const usePersonRemove = () => {
  const queryClient = useQueryClient();

  return useMutation((o: DeleteOptions) => deletePerson(o), {
    onSuccess: () => queryClient.invalidateQueries(['persons']),
  });
};

const removePersonIcon = ({ personId }: DeleteOptions): Promise<void> =>
  axiosInstance.patch(`${API_PERSONS_PATH}/${personId}/image/clear`);

export const usePersonRemoveIcon = () => {
  const queryClient = useQueryClient();

  return useMutation((o: DeleteOptions) => removePersonIcon(o), {
    onSuccess: () => queryClient.invalidateQueries(['persons']),
  });
};
