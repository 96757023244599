export enum DragItemType {
  PERSON = 'person',
  PLACE = 'place',
}

export interface IDragItem {
  id: string;
  type: DragItemType;
  top: number;
  left: number;
}

export type MapDragState = IDragItem[];

enum ActionType {
  SET_STATE,
  SET_ITEM_STATE,
}

export type Action =
  | { type: ActionType.SET_STATE; payload: MapDragState }
  | {
      type: ActionType.SET_ITEM_STATE;
      payload: IDragItem;
    };

export const reducer = (state: MapDragState, action: Action): MapDragState => {
  const { type, payload } = action;
  switch (type) {
    case ActionType.SET_STATE: {
      return payload;
    }
    case ActionType.SET_ITEM_STATE: {
      return state.map((item) =>
        item.id === payload.id && item.type === payload.type ? payload : item,
      );
    }
  }
};

export const setState = (payload: MapDragState): Action => ({
  type: ActionType.SET_STATE,
  payload,
});

export const setItemDragState = (payload: IDragItem): Action => ({
  type: ActionType.SET_ITEM_STATE,
  payload,
});
