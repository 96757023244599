import { FC, ChangeEventHandler, SetStateAction, Dispatch } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Avatar, Button, Modal } from '@mui/material';

import CloseIconButton from '../../Icons/CloseIconButton';
import {
  usePersonRemoveIcon,
  usePersonUpdateIcon,
} from 'packages/frontend/src/hooks/persons';

import css from './styles.module.scss';
import colors from '../../../scss/colors.module.scss';

const classes = classnames.bind(css);

interface Props {
  user: Person;
  icon: string;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export const EditProfilePicture: FC<Props> = ({
  user,
  icon,
  open,
  setOpen,
}) => {
  const { t } = useTranslation();
  const { mutate: updateIcon } = usePersonUpdateIcon();
  const { mutate: removeIcon } = usePersonRemoveIcon();

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const image = e.target.files?.[0];

    if (image) {
      updateIcon(
        { personId: user.id, image },
        { onSettled: () => (e.target.value = '') },
      );
    }
  };

  const handleRemove = () => removeIcon({ personId: user.id });

  return (
    <Modal open={open} onClose={() => setOpen(false)} sx={{ display: 'flex' }}>
      <div className={classes(css.card)}>
        <div className={classes(css.close)}>
          <CloseIconButton
            color={colors.black}
            onClick={() => setOpen(false)}
          />
        </div>
        <Avatar alt={user.name} src={icon} className={classes(css.avatar)} />
        <div className={classes(css.buttons)}>
          <Button variant="contained" component="label">
            {t('profile.picture.change')}
            <input
              type="file"
              accept="image/*"
              onChange={handleChange}
              hidden
            />
          </Button>
          <Button variant="contained" onClick={handleRemove}>
            {t('profile.picture.remove')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
