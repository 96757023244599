import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import classNames from 'classnames';
import { FC } from 'react';
import colors from '../../scss/colors.module.scss';
import css from './IconButton.module.scss';
import { IIconButtonProps } from './types';

const classes = classNames.bind(css);

const CloseIconButton: FC<IIconButtonProps> = ({
  onClick,
  color,
  disabled,
  type,
}) => (
  <IconButton
    className={classes(css.iconButton)}
    onClick={onClick}
    disabled={disabled}
    type={type}
  >
    <CloseIcon sx={{ color: color ?? colors.white }} />
  </IconButton>
);

export default CloseIconButton;
