import classNames from 'classnames';
import { useAtom } from 'jotai';
import { FC, useState } from 'react';
import { usePlaceAdd } from '../../../hooks/places';
import { mapModeAtom } from '../../../store';
import { useMapDragState } from '../../../store/MapDragState';
import { DragItemType } from '../../../store/MapDragState/reducer';
import { useOffices } from '../../../store/office';
import { AddPlaceDialog } from '../AddPlaceDialog';
import css from './styles.module.scss';
import { OfficePerson } from '../OfficePerson';
import { OfficePlace } from '../OfficePlace';
import { DragItem } from '../DragItem';

const classes = classNames.bind(css);

interface DialogData {
  open: boolean;
  position: {
    x: number;
    y: number;
  };
}

/***
 * A sub component for DragDropContainer that contains a map image as background and nested DraggableElements acting as its own "drop target"
 */
export const OfficeMapInner: FC<{ zoomFactor: number }> = ({ zoomFactor }) => {
  // Absolute pixel values might have to be translated into relative%
  // values of the map image size instead to stay consistent between zoom
  // levels and different map image resolutions etc.
  const [mapDragState] = useMapDragState();
  const { selectedOffice } = useOffices();
  const [mapMode] = useAtom(mapModeAtom);
  const addPlace = usePlaceAdd();

  const [dialogData, setdialogData] = useState<DialogData>({
    open: false,
    position: { x: 0, y: 0 },
  });

  if (typeof selectedOffice === 'undefined') return null;

  const handleAddPlaceClick = (e: any) => {
    if (mapMode !== 'add-place') return;

    const { top, left } = e.target.getBoundingClientRect();
    const { clientX, clientY } = e;

    const x = Math.round((clientY - top) * (1 / zoomFactor));
    const y = Math.round((clientX - left) * (1 / zoomFactor));

    setdialogData({
      open: true,
      position: {
        x,
        y,
      },
    });
  };

  const handleAddPlace = (name: string) => {
    addPlace.mutate({
      positionX: dialogData.position.x,
      positionY: dialogData.position.y,
      office: selectedOffice.id,
      name,
    });
    setdialogData({ open: false, position: { x: 0, y: 0 } });
  };

  return (
    <div>
      <img
        id="map-image"
        src={`${process.env.REACT_APP_IMAGE_BUCKET}/${selectedOffice.mapPath}`}
        className={classes(css.map)}
        onClick={handleAddPlaceClick}
        draggable="false"
      />
      {mapDragState?.map((item) => (
        <DragItem
          key={item.id + item.type}
          item={item}
          zoomFactor={zoomFactor}
          dragDisabled={mapMode !== 'move'}
        >
          {item.type === DragItemType.PERSON && (
            <OfficePerson stateId={item.id} />
          )}
          {item.type === DragItemType.PLACE && (
            <OfficePlace stateId={item.id} />
          )}
        </DragItem>
      ))}
      <AddPlaceDialog
        open={dialogData.open}
        onCancel={() =>
          setdialogData({ open: false, position: { x: 0, y: 0 } })
        }
        onConfirm={handleAddPlace}
      />
    </div>
  );
};
