import { Logout, Settings } from '@mui/icons-material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PersonIcon from '@mui/icons-material/Person';
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { Trans } from 'react-i18next';
import { normalizeIcon } from '../../common/icon';
import { usePersons } from '../../hooks/persons';
import { useUser } from '../../store/user';

const MainMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const user = useUser();

  const firstName = (user?.name ?? '').split(' ', 1)[0];
  const icon = normalizeIcon(user);

  const open = !!anchorEl;

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <IconButton
          size="small"
          onClick={(e) => setAnchorEl(e.currentTarget)}
          aria-controls={open ? 'main-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
        >
          <Avatar sx={{ width: 32, height: 32 }} src={icon} />
          <Typography sx={{ ml: 1, color: '#fff' }}>{firstName}</Typography>
          <ArrowDropDownIcon sx={{ ml: 0.5, color: '#fff' }} />
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="main-menu"
        open={open}
        onClose={() => setAnchorEl(null)}
        onClick={() => setAnchorEl(null)}
      >
        <MenuItem component="a" href="/profile">
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <Trans>main.menu.profile</Trans>
        </MenuItem>
        <Divider />
        {/* {user?.admin && ( */}
        <MenuItem component="a" href="settings">
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          <Trans>main.menu.settings</Trans>
        </MenuItem>
        {/* )} */}
        <MenuItem component="a" href="/logout">
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          <Trans>main.menu.logout</Trans>
        </MenuItem>
      </Menu>
    </>
  );
};

export default MainMenu;
