import { Backdrop, CircularProgress } from '@mui/material';
import { useIsFetching, useIsMutating } from '@tanstack/react-query';

interface Props {
  bgColor?: string;
  open?: boolean;
}

const LoadingBackdrop = ({ bgColor, open }: Props) => {
  const isFetching = useIsFetching() > 0;
  const isMutating = useIsMutating() > 0;
  const isWorking = isFetching || isMutating;

  return (
    <Backdrop
      open={typeof open === 'undefined' ? isWorking : open}
      sx={{
        backgroundColor: typeof bgColor !== 'undefined' ? bgColor : undefined,
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <CircularProgress disableShrink color="info" />
    </Backdrop>
  );
};

export default LoadingBackdrop;
