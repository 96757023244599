// Route constants
export const API_PATH = "/api";
export const OFFICES_PATH = "/offices";
export const PERSONS_PATH = "/persons";
export const PLACES_PATH = "/places";

export const AUTH_PATH = "/auth";
export const LOGIN_PATH = "/login";
export const CALLBACK_PATH = "/callback";
export const LOGOUT_PATH = "/logout";
