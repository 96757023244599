import { FC, useEffect, useState } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Avatar, Button } from '@mui/material';
import Select, { SingleValue } from 'react-select';

import {
  usePerson,
  usePersonUpdate,
} from 'packages/frontend/src/hooks/persons';
import { useOffices } from '../../store/office';
import { EditUserName } from './EditUserName';
import { EditProfilePicture } from './EditProfilePicture';
import { normalizeIcon } from '../../common/icon';
import { LanguageSelector } from './LanguageSelector';

import css from './styles.module.scss';

const classes = classnames.bind(css);

interface Props {
  userId: number;
}

export const UserProfile: FC<Props> = ({ userId }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);

  const { data: person, isStale, refetch } = usePerson(userId);
  const { offices } = useOffices();
  const { mutate: updatePerson } = usePersonUpdate();

  useEffect(() => {
    if (isStale) {
      refetch();
    }
  }, [isStale]);

  const handleOfficeChange = (value: SingleValue<Office>) =>
    updatePerson({
      personId: person!.id,
      payload: { mainOffice: value?.id },
    });

  const handleRemoveSeat = () =>
    updatePerson({
      personId: person!.id,
      payload: { positionX: null, positionY: null },
    });

  if (!person) {
    return null;
  }

  const { name, mainOffice, positionX, positionY } = person;

  const nomadOffice = {
    id: null,
    name: t('profile.no-office'),
  } as unknown as Office;

  const options = offices ? [nomadOffice, ...offices] : undefined;
  const office = options?.find((office) => office.id === mainOffice);
  const icon = normalizeIcon(person);

  return (
    <div className={classes(css.card)}>
      <button className={classes(css.leftpane)} onClick={() => setOpen(true)}>
        <Avatar src={icon} alt={name} sx={{ height: 100, width: 100 }} />
      </button>
      <div className={classes(css.rightpane)}>
        <EditUserName user={person} />
        <Select
          placeholder={''}
          options={options}
          value={office}
          onChange={handleOfficeChange}
          getOptionValue={(option) => String(option.id)}
          getOptionLabel={(option) => option.name}
        />
        <Button
          variant="contained"
          onClick={handleRemoveSeat}
          disabled={!positionX && !positionY}
        >
          {t('profile.remove-seat')}
        </Button>
      </div>
      <LanguageSelector />
      <EditProfilePicture
        user={person}
        icon={icon}
        open={open}
        setOpen={setOpen}
      />
    </div>
  );
};
