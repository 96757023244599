import { ThemeProvider } from '@mui/material';
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AxiosError } from 'axios';
import i18n from 'i18next';
import { enqueueSnackbar, SnackbarProvider } from 'notistack';
import { AUTH_PATH, LOGIN_PATH } from 'packages/common/src/constants';
import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { initReactI18next } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import ErrorBoundaryFallback from './pages/FallbackPage';
import LoadingBackdrop from './Components/LoadingBackdrop';
import { i18nConfig } from './i18n/config';
import './scss/global.scss';
import './scss/shared.scss';
import theme from './theme';
import { OfficeContextProvider } from './store/office';
import { UserContextProvider } from './store/user';

i18n.use(initReactI18next).init(i18nConfig);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      useErrorBoundary: true,
      retry: false,
      staleTime: 5 * 60 * 1000,
      // cacheTime: 5 * 60 * 1000, // react-query default
    },
  },
  queryCache: new QueryCache({
    onError: (error) => handleQueryError(error),
  }),
  mutationCache: new MutationCache({
    // onSuccess: () =>
    //   enqueueSnackbar(i18n.t('Saved'), {
    //     variant: 'success',
    //     autoHideDuration: 2000,
    //   }),
    onError: (error) => handleQueryError(error),
  }),
});

createRoot(document.getElementById('root') as HTMLDivElement).render(
  <QueryClientProvider client={queryClient}>
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        maxSnack={2}
      />
      <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
        <BrowserRouter>
          <UserContextProvider>
            <OfficeContextProvider>
              <App />
            </OfficeContextProvider>
          </UserContextProvider>
        </BrowserRouter>
        <LoadingBackdrop />
      </ErrorBoundary>
    </ThemeProvider>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>,
);

const handleQueryError = (error: unknown) => {
  const { message, response } = error as AxiosError;

  if (response?.status === 401) {
    return window.location.replace(
      `${AUTH_PATH}${LOGIN_PATH}?redirect_url=${window.location.href}`,
    );
  }

  const result: string[] = [message];
  const errorMessage: string | undefined = (response?.data as any)?.message;

  if (errorMessage) {
    result.push(errorMessage);
  }

  enqueueSnackbar(
    result.reduce((cur, val) => `${cur}: ${val}`),
    { variant: 'error', preventDuplicate: true },
  );
};
