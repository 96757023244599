import { Box } from '@mui/material';
import classNames from 'classnames';

import { useOffices } from '../store/office';
import { OfficeMap } from '../Components/OfficeMap';
import { UnplacedPersons } from '../Components/OfficeMap/UnplacedPersons';
import { MapDragStateProvider } from '../store/MapDragState';

import css from './HomePage.module.scss';

const classes = classNames.bind(css);

const HomePage = () => {
  const { selectedOffice } = useOffices();

  return (
    <div className={classes(css.content)}>
      <MapDragStateProvider>
        <UnplacedPersons />
        <Box className={classes(css.dragDropBox)}>
          {selectedOffice && <OfficeMap key={selectedOffice.id} />}
        </Box>
      </MapDragStateProvider>
    </div>
  );
};

export default HomePage;
