import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { forwardRef, ReactElement, Ref } from 'react';

interface Props {
  onConfirm: () => void;
  onCancel: () => void;
  titleText: string;
  confirmText: string;
  cancelText: string;
  open: boolean;
  children: React.ReactNode;
}

const ConfirmDialog = ({
  open,
  onConfirm,
  onCancel,
  titleText,
  confirmText,
  cancelText,
  children,
}: Props) => (
  <Dialog
    open={open}
    TransitionComponent={Transition}
    keepMounted
    onClose={onCancel}
    aria-describedby="confirm-dialog-description"
  >
    <DialogTitle>{titleText}</DialogTitle>
    <DialogContent>
      <DialogContentText id="confirm-dialog-description">
        {children}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button variant="contained" onClick={onCancel}>
        {cancelText}
      </Button>
      <Button variant="contained" onClick={onConfirm}>
        {confirmText}
      </Button>
    </DialogActions>
  </Dialog>
);

export const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement<any, any>;
  },
  ref: Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default ConfirmDialog;
