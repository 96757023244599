import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button, Drawer, Typography } from '@mui/material';
import classnames from 'classnames';
import { useOffices } from 'packages/frontend/src/store/office';
import { useState } from 'react';
import { Trans } from 'react-i18next';
import { usePersonsByOfficeAndUnseated } from '../../../hooks/persons';
import css from './styles.module.scss';
import { UnplacedPerson } from './UnplacedPerson';

const classes = classnames.bind(css);

export const UnplacedPersons = () => {
  const [open, setOpen] = useState<boolean>(false);

  const { selectedOffice } = useOffices();

  const unseated = usePersonsByOfficeAndUnseated(selectedOffice);
  const { data: persons } = unseated;

  return (
    <div>
      <div className={classes(css.buttonContainer)}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpen(true)}
          disabled={!persons?.length}
        >
          <Trans>unplaced.title</Trans>
        </Button>
      </div>
      <Drawer
        classes={{ paper: classes(css.drawer) }}
        anchor="right"
        open={open}
        BackdropProps={{ invisible: true }}
        onClose={() => setOpen(false)}
      >
        <Button
          className={classes(css.hideButton)}
          onClick={() => setOpen(false)}
        >
          <Trans>unplaced.hide</Trans>
          <ArrowForwardIcon />
        </Button>
        <Typography variant="h5" sx={{ my: 2 }}>
          <Trans>unplaced.title</Trans>
        </Typography>
        <div className={classes(css.list)}>
          {persons?.map((person) => (
            <UnplacedPerson key={person.id} person={person} />
          ))}
        </div>
      </Drawer>
    </div>
  );
};
