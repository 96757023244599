import Select, { SingleValue } from 'react-select';
import { useTranslation } from 'react-i18next';
import { customStyles } from './SelectorStyles';
import { useAtom } from 'jotai';
import { selectedPersonAtom, selectedPlaceAtom } from '../../store';
import { usePersonsByOffice } from '../../hooks/persons';
import { useOffices } from '../../store/office';

const PersonSelector = () => {
  const { t } = useTranslation();

  const [selectedPerson, setSelectedPerson] = useAtom(selectedPersonAtom);
  const [, setSelectedPlace] = useAtom(selectedPlaceAtom);
  const { selectedOffice } = useOffices();

  const { data: persons } = usePersonsByOffice({
    office: selectedOffice,
    type: 'seated',
  });

  const handleChange = (option: SingleValue<Person>) => {
    setSelectedPerson(option);
    setSelectedPlace(null);
  };

  return (
    <Select
      styles={customStyles}
      placeholder={t('main.selector.people')}
      options={persons}
      value={selectedPerson}
      onChange={handleChange}
      getOptionValue={(option) => option.id.toString()}
      getOptionLabel={(option) => option.name}
    />
  );
};

export default PersonSelector;
