import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useState,
  useEffect,
} from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { useOfficeQuery } from '../hooks/offices';
import { useUser } from './user';

const SESSION_STORAGE_KEY = '__selected_office';
const QUERY_KEY = 'office';

interface State {
  offices?: Office[];
  selectedOffice?: Office;
  setSelectedOffice: (office: Office) => void;
}

const OfficeContext = createContext<State>({
  offices: undefined,
  selectedOffice: undefined,
  // eslint-disable-next-line
  setSelectedOffice: () => {},
});

export const OfficeContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const user = useUser();
  const { data: offices } = useOfficeQuery();
  const [selectedOffice, setSelectedOffice] = useState<Office | undefined>();

  useEffect(() => {
    if (offices?.length && user) {
      const officeId =
        searchParams.get(QUERY_KEY) ??
        sessionStorage.getItem(SESSION_STORAGE_KEY) ??
        user?.mainOffice?.toString();

      if (officeId) {
        setSelectedOffice(
          offices?.find((office) => office.id === parseInt(officeId)) ??
            offices[0],
        );
      } else {
        setSelectedOffice(offices[0]);
      }
    }
  }, [offices, user]);

  // Update query on selectedOffice or route change
  useEffect(() => {
    if (selectedOffice) {
      const query = searchParams;
      query.set(QUERY_KEY, selectedOffice.id.toString());

      setSearchParams(query.toString());
      sessionStorage.setItem(SESSION_STORAGE_KEY, selectedOffice.id.toString());
    }
  }, [selectedOffice, pathname]);

  return (
    <OfficeContext.Provider
      value={{
        offices,
        selectedOffice,
        setSelectedOffice,
      }}
    >
      {children}
    </OfficeContext.Provider>
  );
};

export const useOffices = () => useContext(OfficeContext);
