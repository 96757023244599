import { FC, PropsWithChildren } from 'react';
import classnames from 'classnames';

import css from './styles.module.scss';

const classes = classnames.bind(css);

export const Card: FC<PropsWithChildren> = ({ children }) => (
  <div className={classes(css.card)}>{children}</div>
);
