import { createTheme } from '@mui/material/styles';

import colors from './scss/colors.module.scss';

const theme = createTheme({
  palette: {
    primary: { main: colors.white },
    // primary: { main: '#f04e30', light: '#d75539' },
    // secondary: { main: '#d75539' },
    // text: { primary: '#f6f5f5' },
    info: { main: colors.white },
    error: { main: colors.black },
    secondary: {
      main: colors.black,
    },
  },
});

export default theme;
