import { IDragItem, MapDragState } from '../../store/MapDragState';

export interface Coords {
  positionX: number;
  positionY: number;
}

interface GetClosestFreePositionProps extends Coords {
  mapDragState: MapDragState;
  item: IDragItem;
}

const getClosestItem = ({
  mapDragState,
  item,
  positionX,
  positionY,
}: GetClosestFreePositionProps) =>
  mapDragState
    .filter((elem) => elem.id + elem.type !== item.id + item.type)
    .map((elem) => ({
      ...elem,
      diffX: positionX - elem.top,
      diffY: positionY - elem.left,
    }))
    .sort(
      (a, b) =>
        Math.abs(a.diffX) +
        Math.abs(a.diffY) -
        (Math.abs(b.diffX) + Math.abs(b.diffY)),
    )[0];

// Prevent placing item on top of other item.
// TODO: Check if recalculated position is free.
// NOTE: This is pretty awful. Should probably use trigonometry for distance calculation & new position.
export const getClosestFreePosition = ({
  mapDragState,
  item,
  positionX,
  positionY,
}: GetClosestFreePositionProps): Coords => {
  const closestItem = getClosestItem({
    mapDragState,
    item,
    positionX,
    positionY,
  });

  if (Math.abs(closestItem?.diffX) < 75 && Math.abs(closestItem?.diffY) < 75) {
    return Math.abs(closestItem.diffY) > Math.abs(closestItem.diffX)
      ? {
          positionX,
          positionY:
            closestItem.diffY < 0
              ? closestItem.left - 70
              : closestItem.left + 70,
        }
      : {
          positionY,
          positionX:
            closestItem.diffX < 0 ? closestItem.top - 70 : closestItem.top + 70,
        };
  }

  return { positionX, positionY };
};
