import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import Select, { SingleValue } from 'react-select';
import { usePlacesByOffice } from '../../hooks/places';
import { selectedPersonAtom, selectedPlaceAtom } from '../../store';
import { useOffices } from '../../store/office';
import { customStyles } from './SelectorStyles';

const PlaceSelector = () => {
  const { t } = useTranslation();

  const [selectedPlace, setSelectedPlace] = useAtom(selectedPlaceAtom);
  const [, setSelectedPerson] = useAtom(selectedPersonAtom);
  const { selectedOffice } = useOffices();

  const { data: places } = usePlacesByOffice(selectedOffice);

  const handleChange = (option: SingleValue<Place>) => {
    setSelectedPlace(option);
    setSelectedPerson(null);
  };

  return (
    <Select
      styles={customStyles}
      placeholder={t('main.selector.places')}
      onChange={handleChange}
      value={selectedPlace}
      options={places}
      getOptionValue={(option) => option.id.toString()}
      getOptionLabel={(option) => option.name}
    />
  );
};

export default PlaceSelector;
