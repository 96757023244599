import axios, { AxiosError, AxiosResponse } from 'axios';

const axiosInstance = axios.create({
  // headers: {},
});

// axiosInstance.interceptors.request.use(
//   (config: AxiosRequestConfig) => {
//     console.log('axios/request/config: ', JSON.stringify(config, null, 2));
//     return config;
//   },
//   (error: AxiosError) => Promise.reject(error),
// );

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => response.data,
  // (response: AxiosResponse) => {
  //   console.log('axios/response: ', JSON.stringify(response, null, 2));
  //   return response.data;
  // },
  (error: AxiosError) => Promise.reject(error),
);

export default axiosInstance;
