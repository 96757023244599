import AddLocationIcon from '@mui/icons-material/AddLocation';
import MovingIcon from '@mui/icons-material/Moving';
import {
  ToggleButton as MuiToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { Trans } from 'react-i18next';
import { mapModeAtom, MapModeType } from '../../store';

const ToggleButton = styled(MuiToggleButton)({
  '&.MuiToggleButton-standard': {
    backgroundColor: '#fefefe',
  },
  '&.Mui-selected, &.Mui-selected:hover': {
    backgroundColor: '#abbbba',
  },
});

const ToggleGroup = () => {
  const [mapMode, setMapMode] = useAtom(mapModeAtom);
  // const [user] = useAtom(userAtom);

  return (
    <ToggleButtonGroup
      value={mapMode}
      size="small"
      exclusive
      onChange={(_e, value) => setMapMode(value as MapModeType)}
      aria-label="toggle-mode"
    >
      {/* {user?.admin && ( */}
      <ToggleButton value="add-place">
        <AddLocationIcon sx={{ mr: 1 }} />
        <Trans>main.toggle.add-place</Trans>
      </ToggleButton>
      {/* )} */}
      <ToggleButton value="move">
        <MovingIcon sx={{ mr: 1 }} />
        <Trans>main.toggle.move</Trans>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default ToggleGroup;
