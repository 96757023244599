import { FC } from 'react';
import classNames from 'classnames';
import { useAtom } from 'jotai';
import { Tooltip, Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';

import { mapModeAtom } from '../../../store';
import { usePerson } from '../../../hooks/persons';
import { normalizeIcon } from '../../../common/icon';

import css from './styles.module.scss';

const classes = classNames.bind(css);

export const OfficePerson: FC<{ stateId: string }> = ({ stateId }) => {
  const [mapMode] = useAtom(mapModeAtom);

  const { data: person } = usePerson(parseInt(stateId));

  const icon = normalizeIcon(person);

  const cls = classes(css.person, {
    [css.moveEnabled]: mapMode === 'move',
  });

  return (
    <Tooltip
      title={<Typography color="inherit">{person?.name}</Typography>}
      placement="right"
      enterDelay={0}
      enterTouchDelay={0}
      leaveDelay={200}
      arrow
      PopperProps={{
        onPointerDown: (e) => {
          e.stopPropagation();
        },
      }}
    >
      {person?.icon ? (
        <img
          id={`office-map-person-${person?.id}`}
          src={icon}
          className={cls}
          tabIndex={0}
          draggable={false}
        />
      ) : (
        <PersonIcon
          id={`office-map-person-${person?.id}`}
          sx={{ fontSize: 66 }}
          className={cls}
          tabIndex={0}
        />
      )}
    </Tooltip>
  );
};
