import { IDragItem } from 'packages/frontend/src/store/MapDragState';

interface Position {
  positionX: number;
  positionY: number;
}

const isPositionReserved = ({
  position,
  items,
}: {
  position: Position;
  items: IDragItem[];
}) =>
  !!items.find(
    (item) =>
      item.top === position.positionX && item.left === position.positionY,
  );

export const getFreePosition = (items: IDragItem[]): Position => {
  let position = { positionX: 55, positionY: 55 };

  for (let i = 0; isPositionReserved({ position, items }); i++) {
    position =
      i % 2
        ? { positionY: 55, positionX: position.positionX + 70 }
        : { ...position, positionY: position.positionY + 70 };
  }

  return position;
};
